import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonLogin,
  ButtonWrapper,
  Container,
  HeaderWrapper,
  HeadWrap,
  NavInner,
  NavWrap,
  SingleLine,
} from "../GobalStyle";
import { ContolioLogo } from "../Utils/Images";

export default function Header() {
  const navigate = useNavigate();

  return (
    <HeadWrap>
      <Container>
        <HeaderWrapper>
          <img src={ContolioLogo} alt="Contolio Logo" />
          <ButtonWrapper>
            <ButtonLogin href="https://pm.contolio.com/">Login</ButtonLogin>
            <Button onClick={() => navigate("/BookADemo")}>Book a Demo</Button>
          </ButtonWrapper>
        </HeaderWrapper>
        <SingleLine>
          <NavWrap>
            <NavInner>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>Home</span>
              </NavLink>
            </NavInner>

            <NavInner>
              <NavLink
                to="Features"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>Features</span>
              </NavLink>
            </NavInner>

            <NavInner>
              <NavLink
                to="Comparison"
                className={({ isActive }) => isActive && "active"}
              >
                <span>Comparison</span>
              </NavLink>
            </NavInner>

            <NavInner>
              <NavLink
                to="Pricing"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>Pricing</span>
              </NavLink>
            </NavInner>
          </NavWrap>
        </SingleLine>
      </Container>
    </HeadWrap>
  );
}
