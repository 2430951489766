import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";

import {
  BlackLayer,
  Button,
  Container,
  FirstLayout,
  ForthLayout,
  ForthTextContent,
  ImageWrapper,
  MainContext,
  SecondLayout,
  SecondTextContent,
  SilderTextBox,
  StoreButton,
} from "../GobalStyle";
import { LiveBaseURL } from "../Utils/BaseURL";
import {
  applestore,
  googleplay,
  HomeSecondImage,
  iphone,
} from "../Utils/Images";

export default function Home() {
  return (
    <>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <ForthSection />
      <Footer />
    </>
  );
}

const FirstSection = () => {
  const navigate = useNavigate();

  return (
    <FirstLayout>
      <BlackLayer />
      <Container>
        <MainContext>
          <h4>Modernize your business with one powerful</h4>
          <h2>property management solution</h2>

          <p>
            Finally, property management software that gives you the clarity to
            focus on what matters most. Intuitively designed to delight your
            residents and staff. Smartly automated so you have the time to run
            your business and scale your vision.
          </p>
          <Button className="m-0" onClick={() => navigate("/BookADemo")}>
            Book a Demo
          </Button>
        </MainContext>
      </Container>
    </FirstLayout>
  );
};

const SecondSection = () => {
  const navigate = useNavigate();

  return (
    <SecondLayout>
      <Container>
        <SecondLayoutContainer>
          <ImageWrapper>
            <img src={HomeSecondImage} alt="HouseImage" />
          </ImageWrapper>

          <SecondTextContent>
            <h4>Built for your unique portfolio,</h4>
            <h2>no matter your market or size</h2>
            <p>
              Manage your entire properties portfolio on one intuitive platform.
              With Contolio your team can do anything from anywhere in a fully
              digital environment. Streamlined processes and smart automation
              free your team to focus on your communities and deliver an
              outstanding services to your tenants
            </p>
            <Button onClick={() => navigate("/BookADemo")}>Book a Demo</Button>
          </SecondTextContent>
        </SecondLayoutContainer>
      </Container>
    </SecondLayout>
  );
};

const ThirdSection = () => {
  const [data, setData] = useState([]);
  const getThirdSectionData = async () => {
    try {
      await fetch(`${LiveBaseURL}api/v1/home_data`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  console.log(data, "data---------");

  useEffect(() => {
    getThirdSectionData();
  }, []);
  return (
    <Carousel style={{ position: "relative" }}>
      {data?.map((ele) => (
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100 "
            style={{ height: "675px" }}
            src={ele.image}
            alt="First slide"
          />
          <Container>
            <SilderTextBox>
              <h2>{ele.title}</h2>
              <h3>{ele.subtitle}</h3>
              <p>{ele.description}</p>
            </SilderTextBox>
          </Container>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const ForthSection = () => {
  const navigate = useNavigate();
  return (
    <ForthLayout>
      <img src={iphone} alt="contolio" />

      <ForthTextContent>
        <h2>Tenants</h2>
        <h3>mobile app</h3>
        <p>
          Stay connected with your tenants through a state of the art digital
          channel, when you become part of contolio, your tenants would be
          eligible to use the mobile app where they would have a real time
          access to their portfolio and the smooth and straight through
          maintenance management
        </p>

        <StoreButton>
          <a href="https://apps.apple.com/us/app/contolio/id1618167766">
            <img src={applestore} alt="AppleStore" />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.contolios">
            <img src={googleplay} alt="GooglePlay" />
          </a>
        </StoreButton>
      </ForthTextContent>
    </ForthLayout>
  );
};

const SecondLayoutContainer = styledComponents.div`

display: flex;
@media only screen and (max-width: 992px) {
  flex-direction: column;
}


`;
