import styled from "styled-components";
import { Background, PricingBackground } from "./Utils/Images";

export const Button = styled.button`
  background: #145da0;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-style: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 11px 20px;
  margin: 0 10px;
  cursor: pointer;
  @media only screen and (max-width: 400px) {
    font-size: 12px;
    padding: 6px 10px;
  }
`;

export const ButtonLogin = styled.a`
  background: #145da0;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-style: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 11px 20px;
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  @media only screen and (max-width: 400px) {
    font-size: 12px;
    padding: 6px 10px;
  }
`;

export const MainContext = styled.div`
  width: 700px;
  position: relative;
  z-index: 3;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 54px;
  padding: 80px 40px 0 0;
  color: #ffffff;
  margin: 10px 0;
  h4 {
    font-weight: 400;
    font-size: 44px;
  }
  h2 {
    font-size: 44px;
  }

  p {
    padding: 20px 0;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }

  @media only screen and (max-height: 680px) {
    width: 80%;
    padding: 10px 0;
    margin-top: 100px;
    h4 {
      font-size: 32px;
    }
    h2 {
      font-size: 32px;
    }
    p {
      padding: 10px 0;
      font-size: 18px;
      font-weight: 400;
    }
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 0 20px;
    h4 {
      font-weight: 400;
      font-size: 34px;
    }
    h2 {
      font-size: 34px;
    }

    p {
      padding: 10px 0;
      font-size: 12px;
      line-height: 18px;
      text-align: justify;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    padding: 10px 0;
    h4 {
      font-size: 22px;
    }
    h2 {
      font-size: 22px;
    }
    p {
      padding: 10px 0;
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 10px 0;
    h4 {
      font-size: 18px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      padding: 10px 0;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const BlackLayer = styled.div`
  width: 100%;
  height: 100vh;
  background: #000000;
  opacity: 0.4;
  position: absolute;
  z-index: 1;
`;

export const FirstLayout = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background-image: url(${Background});
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
`;

export const Container = styled.div`
  width: 1200px;
  padding-left: 15px;
  padding-right: 25px;
  max-width: 100%;
  margin: auto;
  position: relative;

  // width: 90%;
  // margin 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const FooterText = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  img {
    width: 90px;
  }
  span {
    font-family: "Montserrat , sans-serif";

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    span {
      display: none;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  background: #000000b3;
`;

export const StoreButton = styled.div`
  width: 100%;
  padding: 40px 0;
  img {
    width: 150px;
    padding: 0 20px 0 0;
    cursor: pointer;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    justify-content: center;
    img {
      padding: 10px 0;
    }
  }
`;

export const ForthTextContent = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  color: #000000;
  h2 {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
  }
  h3 {
    font-weight: 700;
    font-size: 44px;
    line-height: 45px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    font-weight: 400;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 20px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 20px 0 20px 20px;
    text-align: center;
  }
`;

export const ForthLayout = styled.div`
  width: 100%;
  padding: 60px 30px 60px 0;
  display: flex;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

export const SilderTextBox = styled.div`
  width: 55%;
  background: #ffffffcc;
  z-index: 3;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-family: "Montserrat";
  font-style: normal;
  color: #145da0;

  h2 {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
  }
  h3 {
    font-weight: 700;
    font-size: 44px;
    line-height: 54px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    font-weight: 400;
  }

  @media only screen and (max-width: 992px) {
    width: 330px;
    padding: 20px;
    margin-left : 70px;
    margin-right:20px;


    h2 {
      font-weight: 400;
      font-size: 34px;
      line-height: 44px;
    }
    h3 {
      font-weight: 500;
      font-size: 34px;
      line-height: 44px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      text-align: justify;
      font-weight: 400;
    }
    @media only screen and (max-width: 600px) {
      width: 230px;
      padding: 0 10px;
      margin-left : 50px;
      margin-right:10px;

      h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
      }
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
      }
      p {
      //  display: none;
      }
      @media only screen and (max-width: 400px) {
        // display: none;
        margin-left : 40px;
    margin-right:10px;
  }
`;

export const SecondTextContent = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 54px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 0 0 20px;
  h4 {
    font-weight: 400;
    font-size: 44px;
  }
  h2 {
    font-size: 44px;
  }

  p {
    padding: 20px 0;
    font-size: 16px;
    line-height: 19.5px;
    text-align: justify;
  }

  @media only screen and (max-width: 992px) {
    padding: 40px 20px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  margin-top: -75px;
  z-index: 9;
  @media only screen and (max-width: 992px) {
    img {
      width: 80%;
    }
  }
  @media only screen and (max-width: 600px) {
    img {
      width: 100%;
    }
  }
`;

export const SecondLayout = styled.div`
  width: 100%;
  position: relative;
  z-index: 13;
  margin-bottom: 40px;
`;

export const PricingPoints = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  ul {
    list-style: none;
    padding: 5px 0;
    margin: 10px 0 0 0;
  }
  li {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 5px 0;
    line-height: 20px;
    text-align: start;
    color: #999999;
  }
  span {
    padding: 5px;
  }
`;

export const SingleLine1 = styled.div`
  width: 95%;
  height: 1.5px;
  background: #000;
  opacity: 0.2;
`;

export const TextContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 20px 30px;
  color: #000000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  text-align: justify;

  h5 {
    font-size: 22px;
    line-height: 21px;
  }
  h4 {
    font-size: 18px;
    line-height: 21px;
  }
  p {
    font-size: 16px;
    line-height: 21px;
    color: #999999;
  }
  span {
    color: #1bdbe8;
  }
`;

export const LogoSection = styled.div`
  width: 252px;
  background: linear-gradient(180deg, #1bdbe8 0%, #145da0 68.75%);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 190px;
    padding: 20px;
    fill: green;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const PricingBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(61, 107, 192, 0.28);
  margin: 20px 0;
  display: flex;
`;

export const PricingTextContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  color: #ffffff;
  position: relative;
  z-index: 2;
  padding: 120px 0 60px 0;

  h3 {
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
  }
  h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 21px;
    text-align: justify;
  }
`;

export const PricingWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url(${PricingBackground});
  background-size: cover;
`;

export const HeadWrap = styled.div`
  // position: fixed;
  position: absolute;
  top: 0;
  // left: 15px;
  z-index: 99;
  width: 100%;
  margin: 0 auto;
`;

export const SingleLine = styled.div`
  max-width: 100%;
  border-top: 1px solid #ebcfa7;
  display: flex;
  justify-content: end;
`;

export const NavWrap = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 3px 0;
`;

export const NavInner = styled.li`
  list-style: none;
  padding: 10px 0;
  margin-right: 20px;

  a {
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    overflow-x: hidden;
    &:before {
      position: absolute;
      top: -15px;
      content: "";
      left: 0;
      width: 100%;
      transform: translateX(-110%);
      background: #ebcfa7;
      height: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      transition: transform 0.3s ease-in;
    }
  }

  a.active {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #ebcfa7;
    position: relative;

    &:before {
      transform: translateX(0);
    }
  }

  @media only screen and (max-width: 400px) {
    a {
      font-size: 11px;
      padding: 5px 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

// export const Button = styled.div`
//   background: #145da0;
//   border-radius: 8px;
//   font-family: "Montserrat", sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 16px;
//   color: #ffffff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   white-space: nowrap;
//   padding: 11px 20px;
//   margin: 0 20px;
//   cursor: pointer;
// `;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  postion: relative;
  width: 100%;
`;
