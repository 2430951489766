import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import {
  BlackLayer,
  Button,
  Container,
  LogoSection,
  PricingBox,
  PricingPoints,
  PricingTextContent,
  PricingWrapper,
  SingleLine1,
  TextContent,
} from "../GobalStyle";
import { ContolioLogo, TickIcon } from "../Utils/Images";

export default function Pricing() {
  const navigate = useNavigate();
  const style = {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 9,
    background: "#333",
  };
  return (
    <div>
      <PricingWrapper>
        <BlackLayer style={{ minHeight: "100%" }} />

        <Container>
          <PricingTextContent>
            <h3>Pricing</h3>
            <h5>Designed for all property managers sizes</h5>
            <PricingBox>
              <LogoSection>
                <img src={ContolioLogo} alt="" />
              </LogoSection>
              <TextContent>
                <h5>QAR 5</h5>
                <p>per unit / per month</p>
                <h4>
                  Minimum monthly fee <span>QAR 1000</span>
                </h4>
                <SingleLine1 />

                <PricingPoints>
                  <ul>
                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Robust Accounting and Reporting
                    </li>
                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Buildings, Units, Payments and Contracts Management
                    </li>
                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Built-in Virtual Marketing
                    </li>

                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Tenants and Owners Management
                    </li>

                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Online Maintenance Management
                    </li>
                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Fully Responsive and Mobile Experience
                    </li>
                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Tenant Mobile App
                    </li>

                    <li>
                      <span>
                        <img src={TickIcon} alt="Tick" />
                      </span>
                      Continuous Software Updates
                    </li>
                  </ul>
                </PricingPoints>
                <div className="w-100">
                  <Button
                    className="m-2"
                    onClick={() => navigate("/BookADemo")}
                  >
                    Book a Demo
                  </Button>
                  <p>Onboarding fees may apply based on portfolio size.</p>
                </div>
              </TextContent>
            </PricingBox>
          </PricingTextContent>
        </Container>
        <Footer style={style} />
      </PricingWrapper>
    </div>
  );
}
