import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import BookADemo from "../Pages/BookADemo";
import Comparison from "../Pages/Comparison";
import Features from "../Pages/Features";
import Home from "../Pages/Home";
import Pricing from "../Pages/Pricing";

export default function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/BookADemo" element={<BookADemo />} />
          <Route path="/Features" element={<Features />} />
          <Route path="/Comparison" element={<Comparison />} />
          <Route path="/Pricing" element={<Pricing />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
